import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const QuienFuePage = () => (
    <Layout>
        <SEO title="Home"/>
        {/* ============================================================== */}
        {/* Feature 3  */}
        {/* ============================================================== */}
        <div className="up bg-white spacer feature3 mt-5">
            <div className="container">
                {/* Row  */}
                <div className="row">
                    <div className="col-12"><h3 className="title">¿Quién fue Pedro Pérez Garrido?</h3>
                    </div>
                    <div className="col-md-8">
                        <p className="subtitle">Don Pedro Pérez Garrido (1913-1952), conocido como el “Tribuno del Pueblo”,
                        fue uno de los más grandes líderes sociales que luchó contra complicidades e intereses ocultos de los
                        gobiernos autoritarios del entonces Territorio Federal de Quintana Roo, principalmente, contra el desmembramiento
                        del territorio, siendo por todos conocida su interlocución frente al Presidente Cárdenas (citada en múltiples obras,
                          Héctor Aguilar Camín, Francisco Bautista, entre otros).
                        </p>
                        <p>En este sentido, a Don Pedro, se le recuerda de manera especial por formar parte del Comité Pro
                        Territorio, una unión de voluntades que defendió la legitimidad de la identidad, el sentido de
                        pertenencia y el derecho de libre determinación del pueblo quintanarroense, en inconformidad con el desmembramiento
                        del Territorio en 1931 y haciendo un frente común contra las pretensiones de dominio político y económico de sus
                        estados vecinos.</p>
                        <p className="m-t-20">Su vida fue súbita e impunemente arrebatada por la administración de Margarito Ramirez,
                        a manos del propio sobrino de éste, Inocencio Padilla (entonces Secretario de Salud de Quintana Roo), sobreviviendo su
                        esposa, Doña Mercedes Alavez Medina, y sus siete hijos, quienes siguieron siendo objeto de la persecución, amenazas
                        y abusos por parte de quienes, aún, guardaban resentimiento hacia Don Pedro.</p>
                        <p></p>
                    </div>
                    <div className="col-md-4">
                    <br></br>
                    <br></br>
                        <img src="../images/testi/6.png" alt="wrapkit"
                             className="img-fluid"/>
                    </div>
                    <div className="col-md-12">
                    <p>Entre los atropellos e injusticias que tuvo que enfrentar Don Pedro, fue la quema de su hotel “Hotel México”, uno de los primeros
                    hoteles del estado, así como el despojo de la curul de Diputado, por la que fue democráticamente electo.</p>
                    <p>Entre sus luchas más destacadas estuvo la conservación del patrimonio forestal del estado, ya que con rapiña Q. Roo fue despojado de
                    su inventario de maderas preciosas, así como de otro tipo de maderas usadas para el chicle.</p>
                    <br></br>
                    </div>
                    <div className="col-md-12 text-center">
                    {/* <div className="col-md-12 text-center">
                        <video controls>
                            <source src='https://investorcloud.s3.amazonaws.com/fundacionmp/historia.mp4' type="video/mp4" />
                        </video>
                        </div> */}
                    <a href="https://investorcloud.s3.amazonaws.com/fundacionmp/historia.mp4" className="text-black display-4" target="_blank" ><i className="icon-Play-Music"/></a>
                    <br></br>
                    <h3>Ver biografía</h3>
                    </div>
                </div>
            </div>
        </div>


    </Layout>
)

export default QuienFuePage
